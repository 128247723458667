<footer>
    <div class="footer">
        <div>
            <div class="logo">
                <a href="#header">K<span>C</span></a>
            </div>
            <a class="imprint" href="imprint">{{'footer.imprint' | translate }}</a>
        </div>
        <div>&copy; Kutup Cevik {{year}}</div>
        <div class="social-media">
            <a href="https://github.com/KutupCevik" target="_blank"><img src="./../../../../assets/img/icons/git-hub.svg" alt=""></a>
            <a href="mailto:kutup.cevik@outlook.com"><img src="./../../../../assets/img/icons/mail.svg" alt=""></a>
            <a href="https://www.linkedin.com/in/kutup-cevik/" target="_blank"><img src="./../../../../assets/img/icons/in.svg" alt=""></a>
        </div>
    </div>
</footer>