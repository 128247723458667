import { Component } from '@angular/core';

@Component({
  selector: 'app-privacy-police',
  standalone: true,
  imports: [],
  templateUrl: './privacy-police.component.html',
  styleUrl: './privacy-police.component.scss'
})
export class PrivacyPoliceComponent {

}
