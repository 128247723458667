<section>
    <div class="portfolio-introduce">
        <h1 data-aos="fade-on">{{'navbar.portfolio' | translate }}</h1>
        <p data-aos="fade-up">{{'portfolio.description' | translate }}</p>
    </div>
    <div class="portfolio">
        @for (project of projects; track i; let i = $index) {
            <div data-aos="zoom-out" class="project">
                <div data-aos="fade-left">
                    <img src="{{project.img}}" alt="">
                </div>
                <div class="project-introduce">
                    <div>
                        <h2>{{project.name}}</h2>
                        <div class="technologie">
                            @for (technologie of project.technologie; track j; let j = $index) {
                                <span>
                                    {{technologie}}
                                    @if (j < project.technologie.length -1) {
                                        |
                                    }
                                </span>
                            }
                        </div>
                        @if (this.translateService.currentLang == "de") {
                            <p>{{project.descriptionDE}}</p>
                        } @else () {
                            <p>{{project.descriptionEN}}</p>
                        }
                    </div>
                    <div class="project-buttons">
                        <button class="test-button"><a href="{{project.liveTest}}" target="_blank">Live test</a></button>
                        <button class="github-button"><a href="{{project.gitHub}}" target="_blank">Github</a></button>
                    </div>
                </div>
            </div>
        }
    </div>
</section>