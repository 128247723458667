<section>
    <hr data-aos="fade-left">
    <main>
        <div data-aos="fade-on" class="introduce-skills">
            <div>
                <div class="title">
                    <h1>{{'my-skills.my-skills' | translate }}</h1>
                </div>
                <p data-aos="fade-left">{{'my-skills.description' | translate }}</p>
            </div>
            <button data-aos="fade-up"><a href="#contact">{{'my-skills.button' | translate }}</a></button>
        </div>
        <div data-aos="zoom-out" class="skills">
            @for (item of skills; track $index) {
                <div class="skill">
                    <img src="{{item.icon}}" alt="">
                    <span>{{item.name}}</span>
                </div>
            }
        </div>
    </main>
</section>