<section>
    <div>
        <h1 data-aos="fade-down">{{'navbar.contact' | translate }}</h1>
    </div>
    <main>
        <div data-aos="fade-right" class="contact-me-txt">
            <h2>{{'contact.1' | translate }}</h2>
            <p>{{'contact.2' | translate }}</p>
            <p>{{'contact.3' | translate }} <b>{{'contact.4' | translate }}</b></p>
        </div>

        <div>
            <form (ngSubmit)="onSubmit(contactForm)" #contactForm="ngForm" class="contact-form">
                <mat-form-field appearance="outline">
                    <mat-label>Name</mat-label>
                    <input matInput [(ngModel)]="contactData.name" #namelInput="ngModel" name="name" required>
                    @if (namelInput.touched && namelInput.valid) {
                    <mat-icon class="valid-icon" matIconSuffix>check_circle</mat-icon>
                    }
                    @if (namelInput.touched && namelInput.errors?.['required']) {
                    <mat-icon class="invalid-icon" matIconSuffix>error</mat-icon>
                    }
                    @if (namelInput.touched && namelInput.errors?.['required']) {
                    <mat-error>{{'contact.5' | translate }}</mat-error>
                    }
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>E-Mail</mat-label>
                    <input matInput [(ngModel)]="contactData.email" #emailInput="ngModel" name="email" required
                        pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,})$"
                        placeholder="beispiel@example.com">
                    @if (emailInput.touched && emailInput.valid) {
                    <mat-icon class="valid-icon" matIconSuffix>check_circle</mat-icon>
                    }
                    @if (emailInput.touched && emailInput.invalid) {
                    <mat-icon class="invalid-icon" matIconSuffix>error</mat-icon>
                    }
                    @if (emailInput.touched && emailInput.errors?.['required']) {
                    <mat-error>{{'contact.6' | translate }}</mat-error>
                    }
                    @if (emailInput.touched && emailInput.errors?.['pattern']) {
                    <mat-error>{{'contact.7' | translate }}</mat-error>
                    }
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'contact.8' | translate }}</mat-label>
                    <textarea matInput [(ngModel)]="contactData.message" #messagelInput="ngModel" name="message"
                        required></textarea>
                    @if (messagelInput.touched && messagelInput.valid) {
                    <mat-icon class="valid-icon" matIconSuffix>check_circle</mat-icon>
                    }
                    @if (messagelInput.touched && messagelInput.errors?.['required']) {
                    <mat-icon class="invalid-icon" matIconSuffix>error</mat-icon>
                    }
                    @if (messagelInput.touched && messagelInput.errors?.['required']) {
                    <mat-error>{{'contact.9' | translate }}</mat-error>
                    }
                </mat-form-field>

                <mat-checkbox class="checkbox" [(ngModel)]="contactData.legal" name="checkbox" required>{{'contact.10' | translate }} <a
                        href="imprint">{{'contact.11' | translate }}</a> {{'contact.12' | translate }}</mat-checkbox>

                <button (click)="openSnackBar()" mat-raised-button color="primary" type="submit" class="submit"
                    [disabled]="!contactData.name || !contactData.email || !contactData.message || !contactData.legal">{{'contact.button' | translate }}</button>
            </form>
        </div>
    </main>
    <div class="top-button">
        <a href="#"><img src="./../../../../assets/img/icons/up.svg" alt=""></a>
    </div>

</section>