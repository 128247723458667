<header data-aos="fade-up" id="header-section">
    <div class="introduce" data-aos="fade-up">
        <div class="i-am">
            <div class="introduce-0">
                <div data-aos="fade-up" data-aos-anchor="#header-section" data-aos-anchor-placement="top-center" [ngClass]="{'introduce-1': !languageDE(), 'introduce-1-de': languageDE()}">{{'header.i-am' | translate }}</div>
                <div>
                    <div class="introduce-2">Kutup Cevik</div>
                    <div [ngClass]="{'introduce-3': !languageDE(), 'introduce-3-de': languageDE()}">{{'header.developer' | translate }}</div>
                </div>
            </div>
            <button data-aos="fade-left" data-aos-anchor="#header-section" data-aos-anchor-placement="top-center"><a href="#contact">{{'header.contact' | translate }}</a></button>
        </div>
    </div>
    <div class="background">
        <img #image class="img" src="./../../../../assets/img/background/background-curve1.svg" alt="">
    </div>
    <div data-aos="fade-right" data-aos-anchor="#header-section" data-aos-anchor-placement="top-center" class="header-bottom">
        <hr>
        <div class="links-arrow-container">
            <div class="links">
                <a class="icon" href="https://github.com/KutupCevik" target="_blank"><img src="./../../../../assets/img/icons/git-hub.svg" alt=""></a>
                <a class="icon" href="mailto:kutup.cevik@outlook.com"><img src="./../../../../assets/img/icons/mail.svg" alt=""></a>
                <a class="icon" href="https://www.linkedin.com/in/kutup-cevik/" target="_blank"><img src="./../../../../assets/img/icons/in.svg" alt=""></a>
                <a class="mail" href="mailto:kutup.cevik@outlook.com">mail&#64;kutupcevik.com</a>
            </div>
            <div class="arrows"></div>
        </div>
    </div>
</header>