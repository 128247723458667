<section id="my_skills_section">
    <div data-aos="fade-in" class="about-me">
        <h1>{{'navbar.about-me' | translate }}</h1>
        <div class="about-me-txt">
            <div>
                <p>{{'about-me.me' | translate }}</p>
            </div>
            <div class="icon-txt">
                <div>
                    <img src="./../../../../assets/img/icons/bulb.svg" alt="">
                </div>
                <p>{{'about-me.first' | translate }}</p>
            </div>
            <div class="icon-txt">
                <div>
                    <img src="./../../../../assets/img/icons/puzzle.svg" alt="">
                </div>
                <p>{{'about-me.second' | translate }}</p>
            </div>
        </div>
    </div>
    <div data-aos="fade-left" class="headshot-section">
        <div class="headshot">
        </div>
        <hr>
    </div>
</section>