<nav>
    <div class="navbar">
        <div class="logo">
            <a href="">K<Span>C</Span></a>
        </div>

        <div [ngClass]="{'hamburger-menu': screenWidth < 800}">
            <input #checkbox id="menu-toggle" [ngClass]="{'display-none': screenWidth > 799}" type="checkbox" />
            <label [ngClass]="{'display-none': screenWidth > 799}" class="menu-btn" for="menu-toggle">
                <span></span>
            </label>

            <div [ngClass]="{'menu-box': screenWidth < 800, 'navbar-links': screenWidth > 799}">
                <a href="#about-me" (click)="toggleCheckbox($event)">{{'navbar.about-me' | translate }}</a>
                <a href="#my-skills" (click)="toggleCheckbox($event)">{{'navbar.skills' | translate }}</a>
                <a href="#portfolio" (click)="toggleCheckbox($event)">{{'navbar.portfolio' | translate }}</a>
                <a href="#contact" (click)="toggleCheckbox($event)">{{'navbar.contact' | translate }}</a>
                <a class="language" (click)="switchLanguage(); toggleCheckbox($event)">
                    <svg class="language-icon" width="40px" height="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="#ffff" stroke-width="1" stroke-linecap="square" stroke-linejoin="miter" fill="none">
                        <title id="languageIconTitle">Language</title>
                        <circle cx="12" cy="12" r="10"/>
                        <path stroke-linecap="round" d="M12,22 C14.6666667,19.5757576 16,16.2424242 16,12 C16,7.75757576 14.6666667,4.42424242 12,2 C9.33333333,4.42424242 8,7.75757576 8,12 C8,16.2424242 9.33333333,19.5757576 12,22 Z"/>
                        <path stroke-linecap="round" d="M2.5 9L21.5 9M2.5 15L21.5 15"/>
                    </svg>
                    <span class="language-text">{{"navbar.currentLanguage"|translate}}</span>
                </a>
            </div>
        </div>
    </div>
</nav>